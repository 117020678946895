<template>
  <div class="relative pointer-events-none">
    <img src="@/assets/bits/1.png" class="absolute bit-1" />
    <img src="@/assets/bits/2.png" class="absolute bit-2" />
    <img src="@/assets/bits/2.png" class="absolute bit-3" />
    <img src="@/assets/bits/3.png" class="absolute bit-4" />
  </div>
</template>

<script>
export default {
  name: "HomepageBits",
};
</script>

<style scoped>
.bit-1 {
  right: -19%;
  top: 48%;
  transform: rotate(259deg);
  width: 150px;
}
.bit-2 {
  right: -47%;
  top: -22%;
}
.bit-3 {
  right: -47%;
  top: -22%;
}
.bit-4 {
  right: -47%;
  top: -22%;
}
</style>
